@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat-Light.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

html {
  height: 100%;
}

body {
  margin: 0;
  background: #F9F9F9;
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

td, td > *, td.MuiTableCell-body {
  font-size: 14px;
}

/* height fix for inactive wizard steps */

.rsw_2f{ display: none; } .rsw_3G{ display: block; }